import React from "react"
const colors = ["#ffd110", "#f25260", "#475a6d", "#995076", "#edd9a9"]

const ListItem = ({ data }) => (
  <>
    {data.map((item, idx) => {
      const bgColor = colors[idx % 5]
      return (
        <div
          key={idx}
          className="item-container"
          style={{ backgroundColor: bgColor }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="item-rank">{item.rank}</span>
            <a
              href={item.link || ""}
              className="item-name"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                width="92"
                src={item.logo}
                className="item-logo"
                alt={item.name}
              />
              <br />
              <span>{item.name}</span>
            </a>
          </div>
          <span className="item-subs">${item.subs}</span>
        </div>
      )
    })}
  </>
)

export default ListItem
