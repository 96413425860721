import React from "react"
import { Link } from "gatsby"

const SelectRouter = () => {
  return (
    <>
      <div
        className="select"
        onClick={() => {
          const el = document.querySelector(".select")
          el.classList.toggle("open")
        }}
      >
        <div className="select-item">Choose a category</div>
        <Link to="/" className="select-item" style={{ borderTop: "0" }}>
          top
        </Link>
        <Link to="/gaming" className="select-item">
          gaming
        </Link>
        <Link to="/entertainment" className="select-item">
          entertainment
        </Link>
        <Link to="/education" className="select-item">
          education
        </Link>
        <Link to="/people" className="select-item">
          people & blogs
        </Link>
        <Link to="/howto" className="select-item">
          howto & style
        </Link>
        <Link to="/film" className="select-item">
          film & animation
        </Link>
        <Link to="/nonprofit" className="select-item">
          nonprofit & activism
        </Link>
        <Link to="/news" className="select-item">
          news & politics
        </Link>
        <span></span>
      </div>
      <div className="sticky-header">
        <span className="header-item">rank</span>
        <span className="header-item">subscribers</span>
      </div>
    </>
  )
}

export default SelectRouter
