import React from "react"
import ListItem from "./listItem"
const film = [
  {
    name: "Movieclips",
    subs: "27.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_C2tNqDMF7GlYc3QHq1Q5FNBCa-K0A5gVVYA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC3gNmTGu-TTbFPpfSs5kNkg",
    rank: 1,
  },
  {
    name: "Goldmines Telefilms",
    subs: "24.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79d1RjEk0DWQi6ydF7A83v3FVKE5V7TqH2lBQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCyoXW-Dse7fURq30EWl_CUA",
    rank: 2,
  },
  {
    name: "Маша и Медведь",
    subs: "22.5M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78kf4dz0_5f84x2Wjmle_4oQ8gz7dRApCZeRw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCRv76wLBC73jiP7LX4C3l8Q",
    rank: 3,
  },
  {
    name: "Selena Gomez",
    subs: "21.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_rwqSxaKtyb--b-sr4p_bhHgjEPaxsLVpyvw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCPNxhDvTcytIdvwXWAm43cA",
    rank: 4,
  },
  {
    name: "Galinha Pintadinha",
    subs: "17.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-680UGrU4u9jqBp-vZAeSKcJ9mfqveKEGw1g=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCBAb_DK4GYZqZR9MFA7y2Xg",
    rank: 5,
  },
  {
    name: "Totoykids",
    subs: "14.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-a3M2J0ekYJo1xA3cNtMd6-Y5hC4Yz5s5UKw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCy7whoHQXzhYxp9AQmrPFVw",
    rank: 6,
  },
  {
    name: "Erlania e Valentina",
    subs: "14.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l785AV23KvmZP2ty04kUrNIWB290ITM14SLW=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC0ebPa2q1rwZVN7dYemunUQ",
    rank: 7,
  },
  {
    name: "Movieclips Trailers",
    subs: "13.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79EKEo-u0CN_HAwNrNaUdrHJ1nxPbWcX20n=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCi8e0iOVk1fEOogdfu4YgfA",
    rank: 8,
  },
  {
    name: "Goldmines",
    subs: "12.5M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79P1wLsSJaXDOFEfWBXYtO9mn0LGL2vltkgbA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCCrw5RBWF9i8VPt4IxL8IsQ",
    rank: 9,
  },
  {
    name: "Turma da Mônica",
    subs: "11.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_glx-9sGyXsZtu3up6LX4o7qwGz19yFjbI6A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCV4XcEqBswMCryorV_gNENw",
    rank: 10,
  },
  {
    name: "Genevieve's Playhouse - Toy Learning for Kids",
    subs: "10.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78B6UBMSyDFQwZtk8VHS3WoWAZ118waqu-eFw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCK5Q72Uyo73uRPk8PmM2A3w",
    rank: 11,
  },
  {
    name: "Pencilmation",
    subs: "10.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_YpRf0_7TZxNTfad9PxD-8Kez9JxY0VQdaTw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCUAL--p3qAa27luR0IYbjZA",
    rank: 12,
  },
  {
    name: "Rooster Teeth",
    subs: "9.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_kRT_yG9jB6IOUz4xuATWObm-8s9tpJu5ZtQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCzH3iADRIq1IJlIXjfNgTpA",
    rank: 13,
  },
  {
    name: "FAP TV",
    subs: "9.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_UdIvyF6KtJ7EmbinWeu3gbOCZ9yJyB8RGGw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC0jDoh3tVXCaqJ6oTve8ebA",
    rank: 14,
  },
  {
    name: "Jingle Toons",
    subs: "9.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-bOHrxHkkoMTjL-CsDoG3d6RgUTx5iYCIr6A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCK9F8nycURBsR0YlrBsu1Ag",
    rank: 15,
  },
  {
    name: "How It Should Have Ended",
    subs: "9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_YIh9ajdrR2mg785ik9frssxxdS_aq7X3QyA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCHCph-_jLba_9atyCZJPLQQ",
    rank: 16,
  },
  {
    name: "Tanner Fox",
    subs: "8.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79R7HKlk-wRNo91WxOuq-JqvrFoReB_htFrlA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCDLmS9vkPcTz3cAc-c9QIzg",
    rank: 17,
  },
  {
    name: "RocketJump",
    subs: "8.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78PvW_2MzU6S2hRPY7YD3YaFEWH7HltJ5WaqA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCDsO-0Yo5zpJk575nKXgMVA",
    rank: 18,
  },
  {
    name: "Peppa Pig - Official Channel",
    subs: "8.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-UmtqMbAqf7gkjyV7tTuM1Iafj-GB-b4Qmuw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCAOtE1V7Ots4DjM8JLlrYgg",
    rank: 19,
  },
  {
    name: "CinemaSins",
    subs: "8.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78bIk8VJbPA60zuekIM5WuM0W8nzYaSArdMpQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCYUQQgogVeQY8cMQamhHJcg",
    rank: 20,
  },
  {
    name: "Alan Becker",
    subs: "8.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7__OgQDRWao7nAq9AaP0-i80GAGD9sBBtRyAg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCbKWv2x9t6u8yZoB3KcPtnw",
    rank: 21,
  },
  {
    name: "Peppa Pig Español Latino - Canal Oficial",
    subs: "7.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-VY1Trt-t1sjS28Pi-3k4wN3eYrFPBdLTTRQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCrreHSUa5rnuCVDeO8dX4eA",
    rank: 22,
  },
  {
    name: "The Film Theorists",
    subs: "7.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78pr3Vf5f0QIKJob4YX3Em2ADS33b7DyRXr6Q=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC3sznuotAs2ohg_U__Jzj_Q",
    rank: 23,
  },
  {
    name: "Toys Unlimited",
    subs: "7.5M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_gTClJicBjTRzFbVK0hQ8sYKzuL6-ZU_Z4-A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCzIdYMdAtTsWucGCZyZvN6w",
    rank: 24,
  },
  {
    name: "Totoykids Español",
    subs: "7.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-LagHuIl6k6L0OPZCGL3aCIQh1JrOo5nZ2oA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCiqE9bJFJhPEOVGdpj2LdEg",
    rank: 25,
  },
  {
    name: "Official Pink Panther",
    subs: "7.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78vCrsJA9dcV-WpumKDHji5nDADLuCQtvoynQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCFeUyPY6W8qX8w2o6oSiRmw",
    rank: 26,
  },
]

const Film = () => <ListItem data={film} />

export default Film
